<template>
<div class="plan-viewer">

    <h6 class="text-darkblue"

            >
                RECOMMENDED
                <span class="text-uppercase">
                    {{card.name}}
                    </span>
            </h6>
    <div class="rec-container rounded px-3" style="min-height:14em">
    <div class="row row-cols-2 text-left
          recommendation py-2
    "  v-if="card.key=='term_insurance'"
    >
        <div class="col" v-if="zeroCover">
            <small><p class="text-muted mb-0"> Cover</p></small>
             <h6 class="my-text"> You should look for retirement plan and other Insurance coverages. </h6>
        </div>
        <div class="col" v-else >
            <small><p class="text-muted mb-0">Size of Cover</p></small>
            <h5 class="my-text">
                {{(plan.size_of_cover)}}</h5>
        </div>

        <div class="col">
            <small><p class="text-muted mb-0">Duration of Plan</p></small>
            <div v-if="zeroCover">
                 <h6 class="my-text"> - </h6>
            </div>
            <div v-else>
                <h5 class="my-text mb-0">
                    {{plan.plan_duration}}</h5>
                <p class=""><small>{{plan.plam_duration_text}}
                        </small>
                </p>
            </div>
        </div>

        <!-- <div class="col">
            <small><p class="text-muted mb-0 stop-break ">Annual Premium (non&#8209;smoker)</p></small>
            <div v-if="zeroCover">
                <h6 class="my-text"> - </h6>
            </div>
            <div v-else>
                <h5 class="my-text mb-0"> {{plan.premium_ns}}*</h5>
                <p class="">
                    <small>
                        {{plan.premium_ns_percent}}
                    </small>
                </p>
            </div>
        </div> -->

        <div class="col">
            <small><p class="text-muted mb-0">Starts when you are</p></small>
             <div v-if="zeroCover">
                 <h6 class="my-text"> - </h6>
            </div>
            <div v-else>
            <h5 class="my-text mb-0" >
                {{plan.term_start}}</h5>
            <!-- <p class=""><small>you will be {{80}} years old then.</small></p> -->
            </div>
        </div>

        <!-- <div class="col">
            <small><p class="text-muted mb-0 ">Annual Premium (smoker)</p></small>
            <div v-if="zeroCover">
               <h6 class="my-text">-</h6>
            </div>
            <div v-else>
                <h5 class="my-text mb-0"> {{plan.premium_s}}*</h5>
                <p class="mb-1">
                    <small>
                        {{plan.premium_s_percent}}
                    </small>
                </p>
            </div>
        </div> -->

        <!-- <div class="col d-flex align-items-end">

            <button class="bg-green rounded border-0 shadow-sm  p-2 px-3
            text-left  font-weight-bold my-text text-uppercase mb-1"
                @click="$emit('show-email-dialog')"
            >
                email me
            </button>
        </div> -->

        <div class="col-12  ">

            <ul class="micro-text pl-3 mt-2">
                <li>
                    <span class="text-blue"><b>Purpose:</b></span>  Ensure a similar lifestyle of dependents in
                    case of an untimely demise
                </li>
                <li>
                    <span class="text-blue"><b>Pro Tip:</b></span>  No dependents, zero income, and/or no
                    liabilities could make term insurance redundant
                </li>
            </ul>

        </div>


    </div>

     <div class="row row-cols-2 text-left
           rounded recommendation py-2
    "  v-else-if="card.key=='retirement_corpus'"
    >

        <div class="col" >
            <small><p class="text-muted mb-0">Total Retirement Corpus Required</p></small>
            <h5 class="my-text">
                {{(plan.retirement ? plan.retirement.corpus : '-')}}</h5>
        </div>

        <div class="col">
            <small><p class="text-muted mb-0">Monthly Investment to
                 achieve Retirement Corpus</p></small>
            <div>
                <h5 class="my-text mb-0">
                    {{plan.retirement ? plan.retirement.monthly_investment : '-'}}
                </h5>

            </div>
        </div>

        <div class="col">
            <small><p class="text-muted mb-0">Annual Investment</p></small>
            <div>
                <h5 class="my-text mb-0">
                    {{plan.retirement ? plan.retirement.annual_investment_percent : '-'}}%</h5>
                <p class="">
                    <small>
                        of your annual household income
                    </small>
                </p>
            </div>
        </div>

        <div class="col-12">
            <ul class="micro-text pl-3 mt-2">
                        <li>
                            <span class="text-blue"><b>Purpose:</b></span>  Ensure similar lifestyle post-retirement
                        </li>
                        <li>
                            <span class="text-blue"><b>Pro Tip:</b></span>  Earlier retirement
                             planning could substantially reduce the
                              requirement for monthly investment towards retirement
                        </li>
            </ul>
        </div>

    </div>

    <div
      v-else-if="card.key=='critical_illness'"
    >

            <div class="row row-cols-2 text-left
                rounded recommendation py-2"
            >

                <div class="col" >
                    <small><p class="text-muted mb-0">Size of Cover </p></small>
                    <h5 class="my-text">
                        {{plan.illness ? (plan.illness.cover) : '-'}}</h5>
                </div>

                <div class="col">
                    <small><p class="text-muted mb-0">Duration of Cover</p></small>
                    <div>
                        <h5 class="my-text mb-0">
                            {{plan.illness ? plan.illness.duration : '-'}}
                        </h5>
                    </div>
                </div>

            <div class="col-12">


                <ul class="micro-text pl-3 mt-2">
                    <li>
                        <span class="text-blue"><b>Purpose:</b></span>  Ensure minimal financial impact due to
                        critical illness. The current average treatment cost is
                        around INR20 Lakhs. This when combined with
                        increasing cost of treatment and loss in income during
                        recovery and treatment would require a sizeable cover.
                    </li>
                    <li>
                        <span class="text-blue"><b>Pro Tip:</b></span>  The critical illness cover is required for the
                        entire lifetime. The duration and the size of the cover
                        bought could be decided based on availability and
                        affordability
                    </li>
                        <li>
                        <span class="text-blue"><b>Pro Tip:</b></span>  It is also alright
                        to start with a smaller cover
                        now and increase gradually. However, the approach
                        might have practical limitations
                    </li>


                </ul>

            </div>


            </div>


    </div>


    <div class="row row-cols-2 text-left
           rounded recommendation py-2
    "  v-else-if="card.key=='medical_insurance'"
    >

        <div class="col" >
            <small><p class="text-muted mb-0">Size of Cover </p></small>
            <h5 class="my-text">
                {{plan.medical ? (plan.medical.cover) : '-'}}</h5>
        </div>

        <div class="col">
            <small><p class="text-muted mb-0">Duration of Cover</p></small>
            <div>
                <h5 class="my-text mb-0">
                    {{plan.medical ? plan.medical.duration : '-'}}
                </h5>
            </div>
        </div>

         <div class="col-10">

        <ul class="micro-text pl-3 mt-2">
            <li>
                <span class="text-blue"><b>Purpose:</b></span>  Ensure minimal financial impact due to a
                medical situation (other than critical illness)
            </li>
            <li>
                <span class="text-blue"><b>Pro Tip:</b></span>  The medical insurance is required for the
                lifetime and needs to be renewed and revised every
                year
            </li>
                <li>
                <span class="text-blue"><b>Pro Tip:</b></span>  For practical reasons, it might be helpful to
                buy higher cover than recommended as increasing the
                size of cover might be difficult with progressing age and
                changing medical situations.
            </li>
        </ul>

         </div>




    </div>

    </div>




    <div class="row row-cols-2 mx-1 my-2">

        <div v-for="(c,n) in cards" :key="c.name" class="col px-0"
        :class="(n+1)%2==0?'pl-2':''">
            <div class="py-1 "

            >
                <div class="d-flex rounded cursor-pointer  position-relative
                overflow-hidden text-left  my-text cover-card"
                :class="card==c?'border-blue':'greyed'"

                @click="handleCardClick(c)">
                        <div v-if="!isReportAllowed(c)"
                        class="position-absolute d-flex align-items-center
                        justify-content-center h-100 w-100 " style="background-color:#ffffff90">
                            <p class="fas fa-lock text-pri" style="font-size:2em"></p>
                        </div>

                    <div class=" icon-container  "  >
                        <img :src="'/images/result/'+c.icon+'.svg'" alt="">
                    </div>
                    <div class="p-1 d-flex flex-column pl-2 ">
                        <p class="text-gray mt-auto">{{c.name}}</p>
                        <p v-if="isReportUnlocked()"
                        class="larger font-weight-bold mb-auto">{{plan[c.key]}}</p>
                        <p v-else class="text-gray mb-auto"></p>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <small class="mt-auto" style="font-size:0.75em">
            <p @click="$router.push({name:'how-it-works'})"
             class="cursor-pointer my-link mb-0">
            Know more about your recommendation
            </p>
        </small>
 <Modal :show="showAccessReportPopUp && !isReportUnlocked()">
        <div class="d-flex rounded-extra flex-column ">
            <div v-if="$store.state.creditLinkCredits > 0 && $store.state.creditValidity > new Date().toISOString()" class="flex-grow-1   px-2 mb-2 pr-3 small text-muted
                    text-center bg-light rounded mt-3" style="padding: 20px;">
                    <h5 > You can access the report by clicking OK. Note that this report will be shared with the advisor </h5>
                    <div class="d-flex flex-row justify-content-stretch p-0 button-group" style="width: 100px;margin: 0 auto;">
                        <button type="button"
                            class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 bg-pri"
                            data-dismiss="modal"
                            @click="accessReport()">
                            OK
                        </button>
                    </div>
                    <h5 class="mt-3"> If you don't want to share the report with advisor then you can purchase it here or exit</h5>
                    <div class="d-flex flex-row justify-content-stretch p-0 button-group" style="width: 250px;margin: 0 auto;">
                        <button type="button"
                            class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 bg-pri"
                            @click="purchaseReport()">
                            Purchase Report
                        </button>
                        <button type="button"
                            class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 ml-3 bg-sec"
                            data-dismiss="modal"
                            @click="showAccessReportPopUp = false;">
                            Exit
                        </button>
                    </div>
            </div>
          </div>
</Modal>

</div>

</template>

<script>
import Modal from '../common/Modal'

export default {
    props:['plan','formData'],
    components:{
        Modal,
    },
    computed:{
        zeroCover(){
            //
            return this.plan.coverValue == 0 || isNaN(this.plan.coverValue);
        },
        baseUrl(){
            const isRetail = (!this.isLoggedIn && !this.isShareLinkClicked) ? true : false
            return this.$baseUrl+(isRetail?'retail/': 'ifa/')
        },
        baseUrlRetail(){
            return this.$baseUrl+'/retail/'
        },
    },
    methods:{
        async accessReport() {
            this.$store.commit('isretail',false);
            if (this.$store.state.creditLinkCredits <= 0) {
                this.$error('Insufficient Credits !!')
            }
            if (window.location.href.indexOf('credit') > -1 && !this.$store.getters.allow_reports) {
                if (!this.$store.state.user) { // Not logged In
                    if (window.location.href.indexOf('credit') > -1) {
                       this.isShareLinkClicked = true;
                    }
                    let apiRequest;
                    apiRequest = this.$axios.post(this.$base_Node_Url +'generic/generatePlanIFA' , {url: this.baseUrl+'get_recommendation/' , data: this.formData })
                    const res = await apiRequest
                    const planData = res.data.data
                    if(res){
                        this.loadingAccessReport = true;
                        this.$store.commit('setPlanData',planData)
                        this.$store.commit('setFormData',this.formData)
                        if (window.location.href.indexOf('credit') > -1 && !this.$store.getters.allow_reports) {
                            let str = window.location.href.split('credit/')[1];
                            let params = str.split('/');
                            let payload = { creditLinkId: params[0], creditUrl: params[1], isGiftCard: this.$store.state.isGiftCard}
                            try {
                                let res = await this.$axios.post(this.$base_Node_Url+'credit-link/checkCreditAndUpdateCreditForPurchaseReport', payload , {headers: {'Content-Type': 'application/json'}})
                                if (res) {
                                    this.$store.dispatch('getCreditLinkByUrl', {creditLinkId: params[0], creditUrl: params[1], isGiftCard: this.$store.state.isGiftCard})
                                    // setTimeout(() => {
                                        this.loadingAccessReport = false;
                                        this.showAccessReportPopUp = false;
                                        this.$store.commit('unlockReport');
                                    // },100)
                                    this.$success('Report Generated Successfully !!')
                                }
                            } catch(err) {
                                this.$error(err)
                            }
                        }
                    }
                }
            }
        },
        handleCardClick(card){
            this.selectedCard=card;
            if (this.$route.path.indexOf('credit') > -1) {
                if (!this.$store.state.user) { // Not logged In
                    if (this.$store.state.creditLinkCredits > 0 && this.$store.state.creditValidity > new Date().toISOString()) {
                        // this.showAccessReportPopUp = true;
                        this.accessReport()
                        if (this.$store.getters.allow_reports) {
                            this.card=card
                        }
                    } else {
                        this.card=card
                        if(!this.$store.getters.allow_reports){
                            this.$store.dispatch('showReportUnlockPayment')
                        }
                    }
                }
            } else {
                console.log(this.isReportAllowed(card), card)
                if(!this.isReportAllowed(card)){
                    this.$store.dispatch('showReportUnlockPayment')
                }else{
                    this.card=card
                }
            }
        },
        purchaseReport() {
            if(!this.isReportAllowed(this.selectedCard) || window.location.href.indexOf('credit') > -1){
                this.$store.dispatch('showReportUnlockPayment')
            }
            if(window.location.href.indexOf('credit') > -1 && this.$store.getters.allow_reports){
                this.$store.dispatch('showReportUnlockPayment')
            }
        },
        isReportAllowed(card){
            const isTerm = card.key === 'term_insurance'
            // const reportsAllowed = this.$store.getters.allow_reports
            // return isTerm || reportsAllowed
            return isTerm || this.$store.state.reports_unlocked
        },
        isReportUnlocked() {
            return this.$store.getters.allow_reports
        }
    },
    data(){
        const fdata = this.formData
        const retired = fdata.starting_age >= fdata.retirement_age
        const houseIncome =   fdata.annual_income_self+
                        fdata.annual_income_spouse+
                        fdata.annual_income_other

        const allow_reports = false //this.$store.getters.allow_reports
        const showCorpus = !retired && houseIncome > 0

        const term = {name:'Term Insurance',key:'term_insurance',
                icon:'term',value:'8500',ok:true}
        const retirement = {name:'Retirement Corpus',key:'retirement_corpus',
                icon:'retirement',value:'8500',ok:allow_reports}
        const illness = {name:'Critical Illness Cover',key:'critical_illness',
                icon:'illness',value:'54900',ok:allow_reports}
        const medical = {name:'Medical Insurance',key:'medical_insurance',
                icon:'health',value:'7000',ok:allow_reports}

        let cards  =[term,retirement,illness,medical,]



        const zeroCover = !(this.plan.coverValue > 0)
        const showAlt = fdata.starting_age > 50 || zeroCover
        if(allow_reports){

            if(showAlt){
                cards = [retirement,illness,term,medical,]
            }
            if(!showCorpus){
                cards = [illness,medical,retirement,term]
            }
        }
        return{
            cards,
            card:cards[0],
            selectedCard: '',
            showAccessReportPopUp: false
        }
    }

}
</script>

<style>
.rec-container{
    background-color: aliceblue;
    border: 1px solid lightblue;
    padding: 4px 4px;
    margin: 0 2px;
}
.cover-card{
    --icbg: rgb(205, 226, 246);
    background-color: aliceblue;
    /* box-shadow: 0 0 8px lightgray; */
    border: 1px solid var(--icbg);
    cursor: pointer;
}
.icon-container{

    padding: 10px;
    background-color: var(--icbg);
}
.icon-container>img{
     width: 4.0em;
}


.cover-card.greyed{
     border : none;
     opacity: 0.7;
}
.cover-card.greyed > .icon-container{
    background-color: rgb(206, 212, 214);
    color: gray;
    opacity: 0.4;
}
.plan-viewer .cover-card p{
    font-size: 1.1em;

}
.plan-viewer h5{
    font-weight: bold;
}
.plan-viewer .col{
    padding-right: 0;
    padding-left: 16px;
    font-size: 0.8em;
}
.plan-viewer p  {
    font-size:1.2em;
    margin: 0;
}
.plan-viewer h5{
    font-size:1.9em;
}
.plan-viewer button{
    font-size: 1.6em;
}
.plan-viewer small{
    font-size: 0.95em;
}
.plan-viewer{
    font-size: 0.7em;
}

.plan-viewer .larger{
    font-size: 1.3em;
}

@media screen and (max-height:650px) {
    .plan-viewer{
        font-size: 0.6em;
    }
}

.stop-break{
    word-wrap: keep-all;
}
p.micro-text{
    font-size: 0.9em;
    color: gray;

}
#app .greyed{
    color: gray;
    background-color: whitesmoke;
}

.border-blue{
    border: 1px solid rgb(168, 197, 224);
    box-shadow: 0 0 8px var(--icbg);
}
</style>