<template>
      <Modal
    :show="showModal"
    >
        <div class=" position-relative d-flex flex-column
        align-items-center rounded-extra  p-3 p-sm-5"

        >
            <button class="position-absolute fas fa-times small text-pri p-3 ml-auto border-0 bg-transparent"
                style="left:0;top:0;"
                @click="closePaymentDialog"
            />

            <h1 class="mb-3 mt-3 text-darkblue logo-font">Payment</h1>

            <div v-if="loading" class="my-5">

                <h2 class="fas fa-circle-notch rotating"></h2>

            </div>

            <div v-else-if="step==0" class="">
                <p v-if="!isPurchaseCredit" class="lead text-center " style="font-size:2.4em">
                    <span v-if="discounted_amount && discounted_amount<amount">
                        <s class="text-gray disabled mx-1 " style="font-size:0.8em;">
                            ₹{{amount}}
                        </s>
                        <span > ₹{{discounted_amount}} </span>
                    </span>
                    <span v-else > ₹{{amount}} </span>

                </p>
                 <p v-else class="lead text-center " style="font-size:2.4em">
                    <span v-if="discounted_amount && discounted_amount<amount">
                        <s class="text-gray disabled mx-1 " style="font-size:0.8em;">
                            ₹{{amount}}
                        </s>
                        <span > ₹{{discounted_amount}} </span>
                    </span>
                    <span v-else >
                        <s class="text-gray disabled mx-1 " style="font-size:0.8em;">
                            ₹{{price}}
                        </s>
                    ₹{{amount}} </span>

                </p>

                <div class="d-flex align-items-end">
                    <v-input
                        rules=''
                        placeholder='Discount code'
                        label='Discount code' class="mb-4" v-model="discount_code"
                        :disabled="isPurchaseCredit"> </v-input>
                    
                    <button
                        class="p-2 px-4 mx-1 rounded mb-4 bg-white font-weight-bold border-2 "
                        :class="disableApply?' border-gray text-muted disabled  ':'border-sec  text-sec'"
                        @click="applyCode"
                    >
                        {{!isPurchaseCredit ? 'Apply' : 'Applied' }}
                    </button>
                </div>


                <!-- <p class="text-muted " style="font-size:0.9em">
                    please choose a payment gateway:
                </p>
                <div class="d-flex my-2">
                    <button v-for="(pay,i) in gateways" :key="pay.key+i"
                    class="p-2 pl-3 pr-4 mx-1 rounded  font-weight-bold"
                    :class="gateway==pay.key?'bg-darkblue text-white border-0':'bg-white border-pri text-pri'"
                    @click="gateway=pay.key"
                    >
                        <i class="fas lead mr-1 fa-check-circle text-white" >
                        </i>

                        {{$prettify(pay.key)}}
                    </button>

                </div> -->

            </div>



            <div class="d-flex  mt-2" v-if="!loading">
                <button v-if="step!=0" @click="step=0"
                    class="border rounded-extra p-2 px-4 mr-2 font-weight-bold text-pri"
                >
                    Back
                </button>

                <button  class="border rounded-extra p-2 px-5 font-weight-bold bg-green text-pri "
                    @click="handleNext"
                    style="min-height:2em"
                >
                PAY
                </button>

            </div>

            <p class="small text-muted my-2"
                style="max-width:30em;font-size:0.7em"
            >
                  Your security and privacy are important to us. We do not store your
                   credit card information or contact details shared during online payment.
                    Online payments are passed via a secure socket layer to {{$prettify(gateway)}} which
                     is PCI compliant and it ensures that your information is being handled
                      per industry security standards.
            </p>



        </div>
    </Modal>
</template>

<script>
import Modal from '../common/Modal'
import VInput from '../input/VInput'
// import qs from 'querystring'
import QRCode from 'qrcode'


// import {ValidationObserver} from 'vee-validate'
/*const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-API-KEY':process.env.VUE_APP_API_KEY
    }
} */

export default {
    components:{
         Modal,
        VInput,
    // ValidationObserver,

    },
    data(){
        const gateways = [
            {key:'razorpay',color:'var(--pri)'},
            {key:'upay',color:'var(--sec)'}
        ]
        
        return {
            gateways,
            gateway:gateways[0].key,

            name:'',
            email:'',
            mobile:'',

            razor_pay:null,
            discount_code: '',
            applied_code:'',
            planName:'',
            amount:'',
            discounted_amount:'',
            step:0,
            loading:false,
            hash:'',
            order_id:'',
            payment_id: '',
            showModal:true,
            credits: '',
            isPurchaseCredit: false,
            price: '',
            amt_details: {},
            card_img: null

        }
    },
    computed:{
        disableApply(){
            return (!this.discount_code || this.discount_code == this.applied_code)
        }
    },
    methods:{
        handleNext(){
            // if(this.step == 0)
            //{
                if(this.order_id){
                    this.openPaymentGateway()
                }else{
                    this.initiate_payment(true)
                }
            //}
        },
        async applyCode(){
            if(!this.disableApply){
                this.initiate_payment(false)
            }
        },
        async initiate_payment(openPay){
            try {
                this.loading = openPay?2:1
                const {payment_type} = this.$store.state.paymentDialog
                let data = {
                    hash:this.hash ? this.hash : 'ahh22uu33y4yy2iii',
                    gateway:this.gateway,
                    amount: payment_type === "add_credit" || payment_type == 'gift_card' ? Math.round(this.amount * 100) : Math.round(this.amount * 100),
                    discount_code:this.discount_code,

                    email:this.email,
                    mobile: this.mobile,
                    name:this.name ,
                    credits: this.credits ? this.credits : '',
                    payment_type: payment_type
                }
               let res;
               if (payment_type == 'add_credit' || payment_type == 'gift_card') {
                res = await this.$axios.post(this.$base_Node_Url +'pay/createOrderWithRazor',data, { headers: {'Content-Type': 'application/json' }})
               } else {
                // data = qs.stringify(data)
                res = await this.$axios.post(this.$base_Node_Url+'pay/createOrderWithRazor',data, { headers: {'Content-Type': 'application/json' }})
               }
                if(!res.data){
                    throw "The response does not contain data"
                }

                const {gateway_order_id,discounted_amount} = res.data.data
                if(discounted_amount){
                    this.discounted_amount = discounted_amount || this.amount
                    this.applied_code = this.discount_code
                    this.$ga.event('payment','discount','applied');
                    // this.$ga.event('payment','Discount code',this.discount_code);

                } else {
                    this.discounted_amount = Math.round(this.amount * 100)
                }
                this.order_id = gateway_order_id

                // this.step += 1;
                if(openPay){
                    this.openPaymentGateway()
                }

             } catch (err) {
                 const data = err && err.response && err.response.data
                if(data && data.data && data.data == 'Invalid discount code'){
                    this.$error(data.data)
                }else{
                    this.$error(err)
                }
            } finally {
                this.loading = false
            }

        },
        dataURItoBlob(dataURI) {
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            var byteString = atob(dataURI.split(',')[1]);
            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: 'image/png' });
        },
        async handleSuccesfulPayment({payment_id,gateway_order_id,payment_signature}){
            try {
                this.loading = true
                var data = {
                    hash:this.hash,
                    gateway:this.gateway,
                    amount:this.amount,
                    discount_code:this.discount_code,
                    email:this.email,
                    mobile: this.mobile,
                    name:this.name,
                    gateway_order_id: gateway_order_id,
                    payment_signature: payment_signature,
                    payment_id: payment_id,
                    status: 'PURCHASED',
                    credits: this.credits ? this.credits : '',
                    originalPrice: '',
                    planName: '',
                    amt_details: this.amt_details,
                }
                if (this.amount == 499){
                    data.originalPrice = 499
                    data.planName = 'Bronze'
                }else if (this.amount == 999){
                    data.originalPrice = 999
                    data.planName = 'Silver'
                }else if (this.amount == 1499){
                    data.originalPrice = 1499
                    data.planName = 'Gold'
                }
                const {payment_type} = this.$store.state.paymentDialog;
                console.log(payment_type)
                if (payment_type == 'add_credit') {
                    let res = await this.$axios.post(this.$base_Node_Url+'pay/capturePaymentWithRazorWithAddCredits',data , {headers: {'Content-Type': 'application/json'}})
                    this.$store.dispatch('fetchUser')
                    this.$store.commit('showPaymentDialog',null);
                    this.$ga.event('payment','payment','completed');
                    this.$success('Added Credits SuccessFully' || res)
                    window.parent.location.reload();
                } else if (payment_type == 'gift_card') {
                    this.loading = true;
                    let imgUrl = ''
                     setTimeout(async () => {
                         this.loading = true;
                        var formData = new FormData();
                        formData.append('amt_details.credits',this.amt_details.credits);
                        formData.append('amt_details.coupon_code',this.amt_details.coupon_code);
                        formData.append('amt_details.base_price',this.amt_details.base_price);
                        formData.append('amt_details.base_disc_price',this.amt_details.credits);
                        formData.append('amt_details.disc',this.amt_details.disc);
                        formData.append('amt_details.total_base_disc_price',this.amt_details.total_base_disc_price);
                        formData.append('amt_details.bulk_disc',this.amt_details.bulk_disc);
                        formData.append('amt_details.total_disc',this.amt_details.total_disc);
                        formData.append('amt_details.final_price',this.amt_details.final_price);
                        formData.append('amt_details.cart_value',this.amt_details.cart_value);
                        formData.append('recipient_email',this.email);
                        formData.append('recipient_name',this.$store.state.paymentDialog.payment_details.name);
                        formData.append('credits',this.credits);
                        formData.append('payment_id',payment_id);
                        formData.append('gateway_order_id',gateway_order_id);
                        formData.append('payment_signature',payment_signature);
                        formData.append('valid_till', this.$store.state.paymentDialog.payment_details.valid_till);
                        formData.append('expiry_date', this.$store.state.paymentDialog.payment_details.expiry_date)
                        let res = await this.$axios.post(this.$base_Node_Url+'pay/verifyPaymentCaptureWithGiftCard', formData , {headers: {'Content-Type': 'multipart/form-data'}});
                        if (res.status === 200) {
                            formData.append('link_url', res.data.data.link_url[0].url);
                            formData.append('creditLinkId', res.data.data.creditLinkId);
                            let str = (res.data.data.link_url[0].url).split('credit/')[1];
                            let params = str.split('/');
                            await QRCode.toDataURL(res.data.data.link_url[0].url)
                                .then(url => {
                                    this.$store.commit('setGiftCardQRLink', url);
                                })
                                .catch(err => {
                                    console.error(err)
                            })
                            this.$store.commit('unlockGiftCard', true);
                            await this.$html2canvas(this.$store.state.paymentDialog.payment_details.creditCardImgRef, {
                                    logging: false,
                                    // scale: 1.5,
                                    imageSmoothingEnabled: false,
                                    allowTaint: false,
                                    letterRendering: true,
                                    backgroundColor: 'transparent',
                                    // image: { type: 'png', quality: 1 },
                                   // margin:10,
                                    // width: 300,
                                   //  height: 190
                                    //width: window.innerWidth / 2,
                                    // height: window.innerHeight / 2,
                                }).then(function(canvas) {
                                    var ctx = canvas.getContext('2d');
                                    ctx.webkitImageSmoothingEnabled = false;
                                    ctx.mozImageSmoothingEnabled = false;
                                    ctx.imageSmoothingEnabled = false;
                                    imgUrl = canvas.toDataURL('image/png');
                            });
                            this.$store.commit('unlockGiftCard', false);
                            const blob = this.dataURItoBlob(imgUrl);
                            formData.append('card_img', new File([blob], "card_image.png"));
                            let emailRes = await this.$axios.post(this.$base_Node_Url+'pay/emailGiftCard', formData , {headers: {'Content-Type': 'multipart/form-data'}});
                            if (emailRes.status === 200) {
                                 this.$store.commit('showPaymentDialog',null);
                                this.$ga.event('payment','payment','completed');
                                this.$success('Gift Card Created SuccessFully' || res);
                                this.loading = false;
                                this.$store.commit('unlockGiftCard', true);
                                this.$store.dispatch('getCreditLinkByUrl', {creditLinkId: params[0], creditUrl: params[1], isGiftCard: this.$store.state.isGiftCard})
                            }
                        }
                    },1000)
                } else if (payment_type === '1finance_report') {
                    this.$store.commit('unlockOneFinance',true);
                    this.$store.commit('showPaymentDialog',null);
                    this.$ga.event('payment','payment','completed');
                    this.$success('Unlocked reports!')
                } else {
                    // data = qs.stringify(data);
                    // let res = await this.$axios.post(this.$baseUrl+'retail/confirm_payment',data,config)
                    let res = await this.$axios.post(`${process.env.VUE_APP_NODE_API_URL}pay/confirmPayment`,data, { headers: {'Content-Type': 'application/json' }});
                    if(!res.data){throw "The response does not contain data"}


                    // let planData = res.data.data

                    // planData = {hash:planData.hash,...planData.recommendation}

                    // this.$store.commit('setPlanData', planData)
                    this.$store.commit('unlockReport');
                    this.$store.commit('showPaymentDialog',null);
                    this.$store.commit('isretail',true);
                    this.$ga.event('payment','payment','completed');
                    this.$success('Unlocked reports!')

                }


            } catch (err) {
                 this.$error(err)
            }finally{
                this.loading = false
            }
        },
        openPaymentGateway(){
            this.$ga.event('payment','payment','initiated');

            const gWay = this.gateway
            if(gWay == 'razorpay'){
                this.openRazorPay()
            }else{
                alert('Only razorpay is available at this moment')
            }
        },
        handleRazorPayResponse(response){
            const {razorpay_payment_id,razorpay_order_id,razorpay_signature} = response


            const payment_details = {
                payment_id:razorpay_payment_id,
                payment_signature:razorpay_signature,
                gateway_order_id:razorpay_order_id
            }
            this.$store.commit('setPaymentDetails',payment_details);
            this.handleSuccesfulPayment(payment_details)
        },
        closePaymentDialog(){
            this.$store.commit('setGiftCardUnlocked',false)
            this.$store.commit('showPaymentDialog',null)
        },
        openRazorPay(){
            const test_key = 'rzp_test_hUh5P12XRr1Duu'
            const razor_pay_api_key = process.env.VUE_APP_RZP_API_KEY || test_key



            const amount = this.discounted_amount * 100
            var options = {
                "key": razor_pay_api_key, // Enter the Key ID generated from the Dashboard
                "amount": amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": "INR",
                "name": "ProtectMeWell",
                "description": "Unlock reports",
                "image": "https://protectmewell.com/images/logo.png",
                "order_id": this.order_id,  // Pass the `id` obtained in the response of Step 1
                "handler": this.handleRazorPayResponse,
                "modal": {
                    "ondismiss": this.handleModalClose
                },
                "prefill": {
                    "name": this.name,
                    "email": this.email,
                    "contact": this.mobile
                },
                "notes": {
                    "address": "Razorpay Corporate Office"
                },
                "theme": {
                    "color": "#3d5f9f"
                },
                "method":{
                    // netbanking: true,
                    // card: true,
                    // upi: true
                    wallet: false,
                }
            };
            this.razor_pay = new window.Razorpay(options);
            this.razor_pay.open()

        },
        handleModalClose(){
            this.showModal = true
        },
    },
    mounted(){
        this.$ga.event('payment','dialog','opened');
        const {payment_type} = this.$store.state.paymentDialog
        if(payment_type == 'unlock_report'){
            this.isPurchaseCredit = false;
            const planData = this.$store.state.planData
        if (this.$route.query != null && this.$route.query.source != null){
            this.discount_code = this.$route.query.source;
        }else{
            this.discount_code = planData.default_discount_code ? planData.default_discount_code : ''
        }
        console.log(this.discount_code);
        this.amount = ( planData && planData.full_report_cost ) || 199

            // const {hash,email,mobile,name,} = payload;
            const hash = planData.hash
            // const {email} = this.$store.state.formData


            this.hash = hash
            // this.email = 'knamit01@gmail.com'
            // this.mobile = '9999999999'
            this.name = this.$store.state.formData.name
        } else if (payment_type == 'add_credit') {
            this.isPurchaseCredit = true;
            const plan = this.$store.state.paymentDialog.plan;
            this.amount = plan.special_price
            this.email = this.$store.state.user.email
            this.mobile = this.$store.state.user.mobile
            this.name = this.$store.state.user.name
            this.credits = plan.credits
            this.price = plan.price
            this.discount_code = plan.coupon_code
            this.applied_code = this.discount_code
            this.planName = plan.name
        } else if (payment_type == 'gift_card') {
            const payment = this.$store.state.paymentDialog.payment_details;
            this.amount = payment.amt_details.final_price
            this.email = payment.emailId.indexOf('@') > -1 ? payment.emailId : payment.email
=           this.name = payment.recipient_name ? payment.recipient_name : name
            this.credits = payment.credits
            this.price = payment.amt_details.base_price
            this.discount_code = payment.amt_details.coupon_code
            this.applied_code = this.discount_code,
            this.amt_details = payment.amt_details
        } else if (payment_type == '1finance_report') {
            this.amount = 99;
            this.name = 'test'
        }




    }
}
</script>

<style>

</style>